import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import bike1 from '../imgs/bici-elettrica.jpg';
import bike2 from '../imgs/contachilometri.jpg';
import bike3 from '../imgs/porta-bici.jpg';

function Servizi() {
   const { t } = useTranslation();

   return (
      <section>
         <Helmet>
            <title>{t('navbar.servizi')} - Bassano Bike Experience</title>
            <meta  name="description" content={t('pageDesc.servizi')} />
         </Helmet>
         <div className="container pt-6 pb-6">
            <h1 className="pb-2">{t('navbar.servizi')}</h1>
            
            <p><strong>{t('services.point.name')}</strong> {t('services.point.text')}</p>
            
            <ul className="list mb-2">
               {t('services.point.list', { returnObjects: true }).map((ele, idx) =>
                  <li key={idx}>{ele}</li>
               )}
            </ul>

            <p><strong>{t('services.wash.name')}</strong> {t('services.wash.text')}</p>
            <ul className="list mb-2">
               {t('services.wash.list', { returnObjects: true }).map((ele, idx) =>
                  <li key={idx}>{ele}</li>
               )}
            </ul>

            <p><strong>{t('services.charging.name')}</strong> {t('services.charging.text')}</p>

            <p><strong>{t('services.social.name')}</strong> {t('services.social.text')}</p>
            
            <p>{t('services.bassano.text')}</p>

            <p><strong>{t('services.lavanda.name')}</strong> {t('services.lavanda.text')}</p>
         
            <p><strong>{t('services.cortesia.name')}</strong> {t('services.cortesia.text')}</p>
            
            <p><strong>{t('services.partner.name')}</strong> {t('services.partner.text')}</p>
            <ul className="list mb-2">
               {t('services.partner.list', { returnObjects: true }).map((ele, idx) =>
                  <li key={idx}>{ele}</li>
               )}
            </ul>

            <p><strong>{t('services.room.name')}</strong> {t('services.room.text')}</p>
         </div>

         <div className="grid gtc-3 g-2 pb-2">
            <img src={bike1} alt="Bassano Bike Experience" />
            <img src={bike2} alt="Bassano Bike Experience" />
            <img src={bike3} alt="Bassano Bike Experience" />
         </div>         
      </section>
   );
}

export default Servizi;