import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import  emailjs from '@emailjs/browser';

function ContactForm() {
   const { t } = useTranslation();

   const form = useRef();
   const initValue = { name:'', email: '', tel: '', message:'' };
   const [formVals, setFormVals] = useState(initValue),
         [formErrs, setFormErrs] = useState({}),
         [isSubmit, setIsSubmit] = useState(false),
         [isSent, setIsSent] = useState(false);

   const handleChange = e => {
      const { id, value } = e.target;
      setFormVals({ ...formVals, [id]: value });
   };
   
   const handleSubmit = e => {
      e.preventDefault();
      setFormErrs(validate(formVals));
      setIsSubmit(true);
   }

   useEffect(() => {
      if(Object.keys(formErrs).length === 0 && isSubmit) {
         emailjs.sendForm('service_kdf4enp', 'template_33wn7vv', form.current, 'lCA605NVX2Bi-Vycl')
            .then(result => {
               console.log(result.text);
               setFormVals(initValue);
               setIsSent(true);
               setFormErrs({});
               setIsSubmit(false);
            }, error => {
               console.log(error.text);
               setIsSubmit(false);
               setIsSent(false);
            }
         );
      }
   }, [formErrs, isSubmit]);
   

   const validate = (vals) => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
            phoneRegex= /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      
      if (!vals.name) {
         errors.name = t('contactForm.msg.nameErr');
      } else if (vals.name.length < 4) {
         errors.name = t('contactForm.msg.nameShort');
      }

      if (!vals.email) {
         errors.email = t('contactForm.msg.emailErr');
      } else if (!emailRegex.test(vals.email)) {
         errors.email = t('contactForm.msg.emailFormat');
      }

      if (!vals.tel) {
         errors.tel = t('contactForm.msg.telErr');
      } else if (!phoneRegex.test(vals.tel)) {
         errors.tel = t('contactForm.msg.telFormat');
      }
      
      return errors;
   };

   return (
      <motion.form
         ref={form}
         onSubmit={handleSubmit}

         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         <input
            id="name"
            name="name"
            type="text"
            placeholder={t('contactForm.placeholder.name')}
            required
            value={formVals.name}
            onChange={handleChange}
         />
         {formErrs.name &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.name}</h4>
         }

         <input
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            required
            value={formVals.email}
            onChange={handleChange}
         />
         {formErrs.email &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.email}</h4>
         }

         <input
            id="tel"
            name="tel"
            type="tel"
            placeholder={t('contactForm.placeholder.tel')}
            required
            value={formVals.tel}
            onChange={handleChange}
         />
         {formErrs.tel &&
            <h4
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{formErrs.tel}</h4>
         }

         <textarea
            id="message"
            name="message"
            rows="6"
            placeholder={t('contactForm.placeholder.msg')}
            value={formVals.message}
            onChange={handleChange}
         />

         {isSent &&
            <motion.h3
               className="txt-center txt-success"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{t('contactForm.msg.sent')}</motion.h3>
         }

         {Object.keys(formErrs).length !== 0 &&
            <h3
               className="txt-center txt-danger"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
               >{t('contactForm.msg.sendErr')}</h3>
         }

         <input type="submit" value={t('contactForm.sendBtn')}/>
      </motion.form>
   );
}

export default ContactForm;