import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function ErrorPage() {
   return (
      <section className="container txt-center pt-6 pb-6">
         <Helmet>
            <title>Page Not Found - Bassano Bike Experience</title>
         </Helmet>
         
         <h1 className="pb-2">404</h1>
         <h2 className="pb-2">Page Not Found</h2>

         <button className="fw-600" style={{ background: 'var(--bg2)', padding: '.8rem' }}>
            <Link to="/">Back to home</Link>
         </button>

      </section>
   );
}

export default ErrorPage;