import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { useEffect } from 'react';
import useScript from '../hooks/useScript';

function Eventi() {
   const { t } = useTranslation();

   useEffect(() => {
      window.dw = window.dw || function () { (window.dw.q = window.dw.q || []).push(arguments) };
      window.dw('settings','063925b6-b4a9-407a-b2f5-58463ace2aef',
      {
         "context": {"targetRoute":["/eventi","/events","/veranstaltungen"]},

         "lang": document.cookie.split('; ').find((row) => row.startsWith('i18next='))?.split('=')[1],

         "target": "tosc5container",

         "loadingAnimationColor": {
         "main": '#999999',
         "alt": '#B9E0AF'
         }
      });
      window.dw('onPageChange', '063925b6-b4a9-407a-b2f5-58463ace2aef');
   }, []);

   useScript('https://resc.deskline.net/DW5/start/TERRBRENTA/063925b6-b4a9-407a-b2f5-58463ace2aef/index.js')

   return (
      <section className="evt-container pt-6 pb-6">
         <Helmet>
            <title>{t('navbar.eventi')} - Bassano Bike Experience</title>
            <meta name="description" content={t('pageDesc.eventi')} />   
         </Helmet>
         
         <h1 className="txt-center">{t('navbar.eventi')}</h1>
         <p className="txt-center pb-2">{t('eve')}</p>
         
         <div id="tosc5container" style={{ minHeight: "250px" }} />
      </section>
   );
}

export default Eventi;