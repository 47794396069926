import { Helmet } from "react-helmet";
import useScript from '../hooks/useScript';

function CookiePolicy() {
   useScript('https://consent.cookiebot.com/8a08848b-7eaa-4581-a8b0-d33bd5ee56eb/cd.js');
   
   return (
      <section className="container pt-6 pb-6">
         <Helmet>
            <title>Cookie Policy - Bassano Bike Experience</title>
         </Helmet>
         
         <h1>Cookie Policy</h1>

         <div id="CookieDeclaration" />
      </section>
   );
}

export default CookiePolicy;