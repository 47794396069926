function IframeItinerari({ name, url }) {
   return (
      <iframe
         title={name}
         className="itinerari"
         src={url}
         width="100%"
         height="700"
         frameBorder="0"
         scrolling="no"
      />
   );
}

export default IframeItinerari;