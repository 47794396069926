import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import logo from '../imgs/logo.svg';

function Footer() {
   const { t } = useTranslation();
   const dateYear = new Date().getFullYear();
   return (
      <motion.footer
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >
         <div className="container">
            <div className="grid gtc-3 g-2">
               <img src={logo} className="pb-2" alt="Bassano Bike Experience logo" />

               <div>
                  <h3>{t('navbar.contatti')}</h3>
                  <ul>
                     <li><address>Via del Museo 60, 36061 - Bassano del Grappa VI</address></li>
                     <li>Cell: <a href="tel:3394458638">+39 339 4458638</a></li>
                     <li>Email: <a href="mailto:info@alloggicanova.it">info@alloggicanova.it</a></li>
                     <li><a href="https://www.iubenda.com/privacy-policy/39262140/legal" target="_blank" rel="noreferrer">Privacy policy</a> - <Link to="/cookie-policy">Cookie policy</Link></li>
                  </ul>
               </div>

               <div>
                  <a href="https://alloggicanova.it/por-fesr" target="_blank" rel="noopener">
                     <img src="https://alloggicanova.it/wp-content/uploads/2022/02/POR-FESR_banner.png" alt="POR-FESR" />
                  </a>
                  
                  <a href="https://www.arpa.veneto.it/previsioni/it/html/mtg_05.html" target="_blank" rel="noopener">
                     <img className="align-left" src="https://alloggicanova.it/wp-content/uploads/2022/02/logo_arpav-300x32.gif" alt="Arpa Veneto" />
                  </a>
               </div>
            </div>
            
            <p className="txt-center pt-6 pb-2">&copy; Bassano Bike Experience {dateYear}. P.Iva 04242280248.<br />Developed by <a href="https://milanesieditore.it" target="_blank" rel="noreferrer">Milanesi Editore</a>.</p>
         </div>
      </motion.footer>
   );
}

export default Footer;