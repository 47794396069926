import { useTranslation } from 'react-i18next';

function WhatsAppBtn() {
   const { t } = useTranslation();
   
   return (
      <a
         id="wachat"
         href={`https://wa.me/393394458638?text=${t('whatsappBtn.message')}+`}
         target="_blank"
         rel="noreferrer"
      >
         <p className="wamessage">{t('whatsappBtn.cta')}</p>
      </a>
   );
}

export default WhatsAppBtn;