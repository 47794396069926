import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import ImgSlider from '../components/ImgSlider';
import SectionItinerari from '../components/SectionItinerari';

function Home() {
   const { t } = useTranslation();

   const category = [
      {
         url: 'itinerari-del-commercio',
         name: t('itiCat.com')
      },
      {
         url: 'itinerari-di-rigenerazione',
         name: t('itiCat.rige')
      },
      {
         url: 'itinerari-dell\'arte',
         name: t('itiCat.art')
      },
      {
         url: 'itinerari-di-degustazione',
         name: t('itiCat.degu')
      },
      {
         url: 'itinerari-enogastronomici',
         name: t('itiCat.eno')
      }
   ];

	return (
      <>
         <Helmet>
            <title>Bassano Bike Experience - {t('motto')}</title>
            <meta  name="description" content={t('pageDesc.home')} />
         </Helmet>

         <ImgSlider slides={[
            "https:////alloggicanova.it/wp-content/uploads/2021/07/camera-sala.jpg",
            "https://alloggicanova.it/wp-content/uploads/2022/02/escursioni-1.jpg"
         ]}>
            <motion.h1
               initial={{ opacity: 0, y: '100%' }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: .7, ease: 'easeInOut' }}
               exit={{ opacity: 0, y: '100%' }}
               >Bassano Bike Experience</motion.h1>
         </ImgSlider>

         <span id="itinerari" />

         {category.map((ele, idx) =>
            <SectionItinerari
               key={idx}
               side={idx}
               title={ele.name}
               link={ele.url}
            />
         )}
      </>
	);
}

export default Home;