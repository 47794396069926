import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import ContactForm from "../components/ContactForm";

function Contatti() {
   const { t } = useTranslation();

   return (
      <section className="container pt-6 pb-6">
         <Helmet>
            <title>{t('navbar.contatti')} - Bassano Bike Experience</title>
            <meta  name="description" content={`${t('navbar.contatti')} - Bassano Bike Experience - ${t('motto')}`} />
         </Helmet>
         
         <h1 className="pb-2">{t('navbar.contatti')}</h1>

         <ContactForm />

         <iframe className="maps" title="Alloggi Canova Bassano del Grappa Maps" src="https://www.google.com/maps?q=Alloggi+Canova&t=&z=14&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
      </section>
   );
}

export default Contatti;