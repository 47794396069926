import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function SectionItinerari({ side, title, link }) {
   const { t } = useTranslation();

	if (side % 2 === 0) {
      return (
         <section className="grid gtc-2-1 g-2 pb-2">
            <div className="carousel btn-side-right">
               <img
                  src={`/imgs/${link}.jpg`}
                  alt={title + ' - Bassano Bike Experience'}
               />
            </div>

            <div className="box-light">
               <h2>{title}</h2>
               <Link to={link} className="btn">{t('scopriBtn')}</Link>
            </div>
         </section>
      );
   } else {
      return(
         <section className="grid gtc-1-2 g-2 pb-2">
            <div className="box-light">
               <h2>{title}</h2>
               <Link to={link} className="btn">{t('scopriBtn')}</Link>
            </div>

            <div className="carousel btn-side-left">
               <img
                  src={`/imgs/${link}.jpg`}
                  alt={title + ' - Bassano Bike Experience'}
               />
            </div>
         </section>
	   );
   }
}

export default SectionItinerari;