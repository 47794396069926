import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import bottegheBassano from '../imgs/botteghe-bassano.jpg';
import ceramicaNove from '../imgs/ceramica-nove.jpg';
import giovediMercatoBassano from '../imgs/giovedi-mercato-bassano.jpg';
import scopertaMonteGrappa from '../imgs/scoperta-monte-grappa.jpg';
import bikeAdventuresValbrenta from '../imgs/bike-adventures-valbrenta.jpg';
import altaViaCiliegi from '../imgs/alta-via-ciliegi.jpg';
import passeggiataSasso from '../imgs/passeggiata-sasso.jpg';
import scalini4444 from '../imgs/4444-scalini.jpg';
import brentaVille from '../imgs/brenta-ville.jpg';
import villaVilla from '../imgs/villa-villa.jpg';
import torcolatoVille from '../imgs/torcolato-ville.jpg';
import distillerieHard from '../imgs/distillerie-hard.jpg';
import distillerieEasy from '../imgs/distillerie-easy.jpg';
import brentaSapori from '../imgs/brenta-sapori.jpg';
import tradizioneEno from '../imgs/tradizione-enogastronomica.jpg';

function CategoryItinerari() {
   const { t } = useTranslation();

   const { category } = useParams();
   const cat = [
      {
         url: 'itinerari-del-commercio',
         name: t('itiCat.com')
      },
      {
         url: 'itinerari-di-rigenerazione',
         name: t('itiCat.rige')
      },
      {
         url: 'itinerari-dell\'arte',
         name: t('itiCat.art')
      },
      {
         url: 'itinerari-di-degustazione',
         name: t('itiCat.degu')
      },
      {
         url: 'itinerari-enogastronomici',
         name: t('itiCat.eno')
      }
   ];
   const itinerari = {
      'itinerari-del-commercio': [
         {
            name: t('iti.com.1.title'),
            slug: 'botteghe-storiche-bassano',
            img: bottegheBassano
         },
         {
            name: t('iti.com.2.title'),
            slug: 'ceramica-nove',
            img: ceramicaNove
         },
         {
            name: t('iti.com.3.title'),
            slug: 'giovedi-del-mercato-bassano-del-grappa',
            img: giovediMercatoBassano
         }
      ],
   
      'itinerari-di-rigenerazione': [
         {
            name: t('iti.rige.1.title'),
            slug: 'scoperta-del-monte-grappa',
            img: scopertaMonteGrappa
         },
         {
            name: t('iti.rige.2.title'),
            slug: 'bike-adventures-valbrenta',
            img: bikeAdventuresValbrenta
         },
         {
            name: t('iti.rige.3.title'),
            slug: 'alta-via-ciliegi',
            img: altaViaCiliegi
         },
         {
            name: t('iti.rige.4.title'),
            slug: 'passeggiata-valbrenta-lungo-sentiero-antonia-del-sasso',
            img: passeggiataSasso
         },
         {
            name: t('iti.rige.5.title'),
            slug: 'escursione-4444-scalini-valbrenta',
            img: scalini4444
         }
      ],
      
      'itinerari-dell\'arte': [
         {
            name: t('iti.art.1.title'),
            slug: 'parco-del-brenta-ville',
            img: brentaVille
         },
         {
            name: t('iti.art.2.title'),
            slug: 'villa-in-villa',
            img: villaVilla
         }
      ],
   
      'itinerari-di-degustazione': [
         {
            name: t('iti.degu.1.title'),
            slug: 'colline-del-torcolato-ville',
            img: torcolatoVille
         },
         {
            name: t('iti.degu.2.title'),
            slug: 'grappa-distillerie-hard',
            img: distillerieHard
         },
         {
            name: t('iti.degu.3.title'),
            slug: 'grappa-distillerie-easy',
            img: distillerieEasy
         }
      ],
      
      'itinerari-enogastronomici': [
         {
            name: t('iti.eno.1.title'),
            slug: 'brenta-sapori',
            img: brentaSapori
         },
         {
            name: t('iti.eno.2.title'),
            slug: 'tradizione-enogastronomica',
            img: tradizioneEno
         }
      ]
   }

   const pageName = cat.filter(cat => cat.url === category)[0].name;
   const itinerariList = itinerari[category];

   // https://stackoverflow.com/questions/72796042/404-page-not-found-not-working-react-router-v6-using-more-than-one-param
   
	return (
      <>
         <Helmet>
            <title>{pageName} - Bassano Bike Experience</title>
            <meta  name="description" content={`${pageName} - Bassano Bike Experience - ${t('motto')}`} />
         </Helmet>

         <motion.section
            className="container pt-6 pb-6"

            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: .8, ease: 'easeInOut' }}
            exit={{ opacity: 0, y: '100%' }}
         >
            <h1 className="pb-2">{pageName}</h1>

            <div className="grid gtc-3 g-2 align-items-start">

               {itinerariList.map((itinerario, idx) =>
                  <Link to={`/${category}/${itinerario.slug}`} key={idx}>
                     <article>
                        <img src={itinerario.img} className="mb-2" alt={itinerario.name + ' - Bassano Bike Experience'} />
                        <h2>{itinerario.name}</h2>
                     </article>
                  </Link>
               )}

            </div>

         </motion.section>
      </>
	);
}

export default CategoryItinerari;