import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import IframeItinerari from '../components/IframeItinerari';
import ContactForm from '../components/ContactForm';

import bottegheBassano from '../imgs/botteghe-bassano.jpg';
import ceramicaNove from '../imgs/ceramica-nove.jpg';
import giovediMercatoBassano from '../imgs/giovedi-mercato-bassano.jpg';
import scopertaMonteGrappa from '../imgs/scoperta-monte-grappa.jpg';
import bikeAdventuresValbrenta from '../imgs/bike-adventures-valbrenta.jpg';
import altaViaCiliegi from '../imgs/alta-via-ciliegi.jpg';
import passeggiataSasso from '../imgs/passeggiata-sasso.jpg';
import scalini4444 from '../imgs/4444-scalini.jpg';
import brentaVille from '../imgs/brenta-ville.jpg';
import villaVilla from '../imgs/villa-villa.jpg';
import torcolatoVille from '../imgs/torcolato-ville.jpg';
import distillerieHard from '../imgs/distillerie-hard.jpg';
import distillerieEasy from '../imgs/distillerie-easy.jpg';
import brentaSapori from '../imgs/brenta-sapori.jpg';
import tradizioneEno from '../imgs/tradizione-enogastronomica.jpg';


function Itinerario() {
   const { t } = useTranslation();

   const { category } = useParams(),
         { itinerarioSlug } = useParams();

   const cat = [
      {
         url: 'itinerari-del-commercio',
         name: t('itiCat.com')
      },
      {
         url: 'itinerari-di-rigenerazione',
         name: t('itiCat.rige')
      },
      {
         url: 'itinerari-dell\'arte',
         name: t('itiCat.art')
      },
      {
         url: 'itinerari-di-degustazione',
         name: t('itiCat.degu')
      },
      {
         url: 'itinerari-enogastronomici',
         name: t('itiCat.eno')
      }
   ];
   const itinerari = {
      'itinerari-del-commercio': [
         {
            name: t('iti.com.1.title'),
            slug: 'botteghe-storiche-bassano',
            img: bottegheBassano,
            text: t('iti.com.1.text'),
            list: [
               'Bortolo Nardini',
               'La bottega del baccalà',
               'La bottega del pane',
               'Inedito Lab',
               'Ristorante Trevisani',
               'Antico ristorante Al cardellino'
            ],
            frameUrl: 'https://www.komoot.it/tour/962809129/embed?share_token=aU2MYZzYsrW2lVW5abYWdKExn2AHDsI69qkcducpFrSrfGNmN1&profile=1'
         },
         {
            name: t('iti.com.2.title'),
            slug: 'ceramica-nove',
            img: ceramicaNove,
            text: t('iti.com.2.text'),
            list: [
               'Villa Morosini Cappello',
               'Mulino Pestasassi',
               'I cuchi di Nove',
               'La mura Antonibon di A.Tasca',
               'La piazza di Marostica',
               'Villa Angarano'
            ],
            frameUrl: 'https://www.komoot.it/tour/962805543/embed?share_token=ajnsgi2XB2GClLj5xKoZWPm2MbC8CMzM9U8CjR9fZ1ga5XnKtU&profile=1'
         },
         {
            name: t('iti.com.3.title'),
            slug: 'giovedi-del-mercato-bassano-del-grappa',
            img: giovediMercatoBassano,
            text: t('iti.com.3.text'),
            list: [
               'Le piazze',
               'Bellavista Palazzo Sturm',
               'Ponte degli Alpini',
               'Museo Poli',
               'Castello degli Ezzelini'
            ],
            frameUrl: 'https://www.komoot.it/tour/962803226/embed?share_token=aK71crEVOoi19L4y38kaBCAf8Bog1syLQqAy6M2508Jr5uGS95&profile=1'
         }
      ],
   
      'itinerari-di-rigenerazione': [
         {
            name: t('iti.rige.1.title'),
            slug: 'scoperta-del-monte-grappa',
            img: scopertaMonteGrappa,
            text: t('iti.rige.1.text'),
            list: [
               'Punto panoramico Col Campeggia',
               'Ex cimitero militare',
               'Cima Grappa'
            ],
            frameUrl: 'https://www.komoot.it/tour/962734736/embed?share_token=aE7x3gpIT3UpmwyhuWHwp4DdLXOt1aBirJMG8hK4HP38BHLwol&profile=1'
         },
         {
            name: t('iti.rige.2.title'),
            slug: 'bike-adventures-valbrenta',
            img: bikeAdventuresValbrenta,
            text: t('iti.rige.2.text'),
            list: [
               'Villa Angarano',
               'Grotte di Oliero',
               'Onda Selvaggia - Kayak',
               'Museo Hemingway',
               'Villa Ca’Erizzo'
            ],
            frameUrl: 'https://www.komoot.it/tour/961818530/embed?share_token=a2LNxpnh0kmtqzn2R0jObp47pVCcI2lIoRJ08lFl3h8F3tCH53&profile=1'
         },
         {
            name: t('iti.rige.3.title'),
            slug: 'alta-via-ciliegi',
            img: altaViaCiliegi,
            text: t('iti.rige.3.text'),
            list: [
               'Piazza degli Scacchi e Castello di Marostica',
               'Monte Corso',
               'Cimitero Inglese',
               'Villa Angarano'
            ],
            frameUrl: 'https://www.komoot.it/tour/961767232/embed?share_token=a2KMnS0HmqJZ8l7iZii3S8UdsSqoTJ9Pbrjo0wOtSyCVD2eMxS&profile=1'
         },
         {
            name: t('iti.rige.4.title'),
            slug: 'passeggiata-valbrenta-lungo-sentiero-antonia-del-sasso',
            img: passeggiataSasso,
            text: t('iti.rige.4.text'),
            list: [
               'Villa Angarano',
               'Grotte di Oliero',
               'Sentiero Antonia dal Sasso',
               'Museo Hemingway',
               'Villa Ca’Erizzo'
            ],
            frameUrl: 'https://www.komoot.it/tour/962943564/embed?share_token=aL9wO2NxfP47rahRFvnoikx6hNpsjfCYp1p3fm3XAdUdFFr4fU&profile=1'
         },
         {
            name: t('iti.rige.5.title'),
            slug: 'escursione-4444-scalini-valbrenta',
            img: scalini4444,
            text: t('iti.rige.5.text'),
            list: [
               'Villa Angarano',
               'Grotte di Oliero',
               'Sentiero Calà del Sasso',
               'Museo Hemingway',
               'Villa Ca’Erizzo'
            ],
            frameUrl: 'https://www.komoot.it/tour/962955048/embed?share_token=aJvf9Dax2kD1oL7IV69O4VgRKZ0Cf7Z8E93c5y7tDWOYq8fR42&profile=1'
         }
      ],
      
      'itinerari-dell\'arte': [
         {
            name: t('iti.art.1.title'),
            slug: 'parco-del-brenta-ville',
            img: brentaVille,
            text: t('iti.art.1.text'),
            list: [
               'Villa Ca’Erizzo',
               'Villa Angarano',
               'Lago di Camazzole',
               'Villa Contarini',
               'Villa Gallarati Scotti',
               'Le mura medievali di Cittadella',
               'Bolle di Nardini'
            ],
            frameUrl: 'https://www.komoot.it/tour/962550521/embed?share_token=aROItSTVoBwDVyCIqN3zIJQ1bwHBE3fWWA1N6ns543NjFy6Qtw&profile=1'
         },
         {
            name: t('iti.art.2.title'),
            slug: 'villa-in-villa',
            img: villaVilla,
            text: t('iti.art.1.text'),
            list: [
               'Villa Negri Piovene',
               'Villa degli Armeni ad Asolo',
               'La Rocca d’Asolo',
               'Villa Rinaldi',
               'Villa Barbaro a Maser',
               'Barco della Regina Cornaro',
               'Tomba Brion'
            ],
            frameUrl: 'https://www.komoot.it/tour/962348397/embed?share_token=amWe8bKR2RbzbcE4Bo45MDKodosQy5GINmLPaqFH1Fynro8yZg&profile=1'
         }
      ],
   
      'itinerari-di-degustazione': [
         {
            name: t('iti.degu.1.title'),
            slug: 'colline-del-torcolato-ville',
            img: torcolatoVille,
            text: t('iti.degu.1.text'),
            list: [
               'Villa Morosini Cappello',
               'Distilleria Poli',
               'Cantina sociale Breganze',
               'Cantina Col Dovigo',
               'Villa Angarano'
            ],
            frameUrl: 'https://www.komoot.it/tour/962401605/embed?share_token=aRhd4fJQ7LxCE73BzDfETlc05nbWnXQ7u78gYtqWFnq7Diw6ys&profile=1'
         },
         {
            name: t('iti.degu.2.title'),
            slug: 'grappa-distillerie-hard',
            img: distillerieHard,
            text: t('iti.degu.2.text'),
            list: [
               'Museo Poli',
               'Ponte Vecchio',
               'Distilleria Nardini',
               'Distilleria Poli',
               'Piazza degli scacchi a Marostica'
            ],
            frameUrl: 'https://www.komoot.it/tour/961739568/embed?share_token=a21wQloJcQ8o9IuArVNIhnNt2H41oK7bxMdQyOM3LW3EtUu33G&profile=1'
         },
         {
            name: t('iti.degu.3.title'),
            slug: 'grappa-distillerie-easy',
            img: distillerieEasy,
            text: t('iti.degu.3.text'),
            list: [
               'Museo Poli',
               'Ponte Vecchio',
               'Distilleria Nardini',
               'Distilleria Poli',
               'Piazza degli scacchi a Marostica'
            ],
            frameUrl: 'https://www.komoot.it/tour/961715164/embed?share_token=aOiCJL5I2EpMPJOqvz5CqGPDklEJ3cWWT4Pm4BiaRUhtsDqNdp&profile=1'
         }
      ],
      
      'itinerari-enogastronomici': [
         {
            name: t('iti.eno.1.title'),
            slug: 'brenta-sapori',
            img: brentaSapori,
            text: t('iti.eno.1.text'),
            list: [
               'Ponte Vecchio',
               'Villa Angarano',
               'Grotte di Oliero',
               'Villa Ca’Erizzo',
               'Antico laboratorio San Giuseppe'
            ],
            frameUrl: 'https://www.komoot.it/tour/962977117/embed?share_token=aI9wAJoK6O6UF1VKqPEJ8BlegjiwXkvNBD3D8PJVLeMyuHmpcK&profile=1'
         },
         {
            name: t('iti.eno.2.title'),
            slug: 'tradizione-enogastronomica',
            img: tradizioneEno,
            text: t('iti.eno.2.text'),
            list: [
               'Ponte Vecchio',
               'Piazza degli Scacchi di Marostica',
               'Villa Morosini',
               'Villa Zanchetta',
               'Cantina sociale Breganze',
               'Cantina Col Dovigo'
            ],
            frameUrl: 'https://www.komoot.it/tour/962980316/embed?share_token=arwxLLWIR8NYWoiBFErD61ixAVOF50Rd0ksemcRz397UdqWWSF&profile=1'
         }
      ]
   }

   const catKey = cat.filter(cat => cat.url === category)[0].name;
   const itinerario = itinerari[category].filter(ele => ele.slug.includes(itinerarioSlug))[0];

	return (
      <>
         <Helmet>
            <title>{itinerario.name} - Bassano Bike Experience</title>
            <meta name="description" content={`${itinerario.name} - Bassano Bike Experience - ${t('motto')}`} />
         </Helmet>

         <motion.section
            className="container pt-6 pb-6"

            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: .8, ease: 'easeInOut' }}
            exit={{ opacity: 0, y: '100%' }}
         >
            <h3>{catKey}</h3>
            <h1 className="pb-2">{itinerario.name}</h1>

            <img src={itinerario.img} className="mb-2" alt={itinerario.name + ' - Bassano Bike Experience'} />
            
            <p>{itinerario.text}</p>
            
            <h2>{t('iti.title')}</h2>

            <ul className="list mb-3">
               {itinerario.list.map((ele, idx) =>
                  <li key={idx}>{ele}</li>
               )}
            </ul>

            {/*
            <div className="grid gtc-3 g-2 align-items-start mb-3">
               <div className="grid gtc-1-2 g-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.45 55.45" fill="var(--primary)"><path d="M27.72,4.8A22.93,22.93,0,1,1,4.8,27.72,22.95,22.95,0,0,1,27.72,4.8m0-4A26.93,26.93,0,1,0,54.65,27.72,26.92,26.92,0,0,0,27.72.8Z"/><path d="M23.18,39.27a2.09,2.09,0,0,1-.7-.15,1.71,1.71,0,0,1-.82-2.27l3.55-7.57a3,3,0,0,1-.43-1.54,3,3,0,0,1,3-3,3.08,3.08,0,0,1,2.54,1.41l15.33.57a1.69,1.69,0,0,1,0,3.38h-.12l-15.37-.57a3,3,0,0,1-1.89,1.18l-3.56,7.58a1.67,1.67,0,0,1-1.51,1h0Z"/><path d="M27.79,25.11h0a2.67,2.67,0,0,1,2.31,1.4l15.54.58a1.31,1.31,0,1,1,0,2.62H45.5L30,29.15A2.59,2.59,0,0,1,28,30.36l-3.65,7.77a1.3,1.3,0,0,1-1.17.76h0a1.38,1.38,0,0,1-.56-.12A1.31,1.31,0,0,1,22,37l3.64-7.77a2.49,2.49,0,0,1-.48-1.51,2.63,2.63,0,0,1,2.63-2.63m0-.75a3.38,3.38,0,0,0-3.38,3.38,3.13,3.13,0,0,0,.38,1.56l-3.47,7.4a2.07,2.07,0,0,0,1,2.75,2.15,2.15,0,0,0,.88.19,2,2,0,0,0,1.87-1.2L28.53,31a3.32,3.32,0,0,0,1.8-1.12l15.15.56h.22a2.06,2.06,0,0,0-.05-4.12l-15.12-.57a3.39,3.39,0,0,0-2.72-1.41h0Z"/></svg>
                  <p>
                     <b>Durata:</b>
                     <br />1:15 h
                  </p>
               </div>

               <div className="grid gtc-1-2 g-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.5 55.5" fill="none" stroke="var(--primary)" stroke-width="4px"><line x1="5" y1="27.7" x2="50.4" y2="27.7"/><line x1="5" y1="22.2" x2="5" y2="33.3"/><line x1="50.4" y1="22.2" x2="50.4" y2="33.3"/></svg>
                  <p>
                     <b>Distanza:</b>
                     <br />4,76 km
                  </p>
               </div>

               <div className="grid gtc-1-2 g-1">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.45 55.45"><polyline fill="none" stroke="var(--primary)" stroke-width="4px" points="3.31 50.46 9.74 32.44 16.83 43.3 24.37 24.52 31.49 39.22 43.09 4.99 52.14 37.89"/></svg>
                  <p>
                     <b>Salita:</b>
                     <br />30 m
                  </p>
               </div>
            </div>
            */}

            <IframeItinerari
               name={itinerario.name}
               url={itinerario.frameUrl}
            />
         </motion.section>

         <motion.section
            className="container pb-6"

            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: .8, ease: 'easeInOut' }}
            exit={{ opacity: 0, y: '100%' }}
         >
            <h2 className="txt-center pb-2">{t('chiediInfo')}</h2>

            <ContactForm />
            
         </motion.section>
      </>
	);
}

export default Itinerario;