import { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';

function ImgSlider({ slides, children }) {
   const [current, setCurrent] = useState(0),
         length = slides.length,
         time = 4000;

   const handleNext = useCallback(() => {
      current + 1 >= length ?
         setCurrent(0) :
         setCurrent(current + 1);
   }, [current, length]);
   
   const handlePrev = () => {
      current === 0 ?
         setCurrent(length - 1) :
         setCurrent(current - 1);
   };

   useEffect(() => {
      const autoplay = setInterval(handleNext, time);
      return () => clearInterval(autoplay);
   }, [handleNext]);

   return (
      <motion.section
         className="slider flex space-around g-6 mb-2"
         style={{ backgroundImage: `url(${slides[current]})` }}

         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: .5, ease: 'easeInOut' }}
         exit={{ opacity: 0 }}
      >

         {children}

         <button className="prev" onClick={handlePrev}>
            <span className="sr-only">Previous Slide</span>
         </button>

         <button className="next" onClick={handleNext}>
            <span className="sr-only">Next Slide</span>
         </button>
      </motion.section>
   );
}

export default ImgSlider;